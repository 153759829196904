import * as React from 'react'
import { Link } from 'gatsby'
let Scroll = require('react-scroll');
let scroller = Scroll.scroller;
var Element = Scroll.Element;

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import styled from "@emotion/styled";
import {fonts} from "../styles/variables";
import OfferForm from "../components/OfferForm";
import TwitterGuestReviews from "../components/TwitterGuestReviews";
const img = require('../../assets/images/slider/v7_07.jpg')

const P = styled.p`
font-family: ChaparralPro-Light, serif;
font-weight: normal;
font-size: 1.1em;
letter-spacing: 0.1em;
color: #333;
padding-bottom: 2.5em;
line-height: 1.5em;`


const FirstP = styled.p`
font-family: ${fonts.chapaProReg} !important;
font-weight: normal;
font-size: 1.3em;
letter-spacing: 0.1em;
color: #333;
padding-bottom: 2.5em;
line-height: 1.5em;
font-style:italic;

`

const Span = styled.span`

  text-decoration:line-through;
  font-weight:bold;
  `

const Flex = styled.div`
width:100%;
display:flex;
justify-content:space-between;


 @media screen and (max-width: 870px) {
 display:block;
 }
`

const Img = styled.img`

height:805.98px;
margin-left:24px;


 @media screen and (max-width: 870px) {
 margin-top:6em;
width:100%;
height:auto;
margin-left:0px;
 }
`


const Crossed = styled.p

class Philosophie extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }
  componentDidMount() {
    scroller.scrollTo('header', {
      duration: 1500,
      smooth: true,
    })
  }


  render() {
    return (
  <IndexLayout>
    <Page>
      <Container>
        <Element id={'header'}/>
        <br/>

        <FirstP> „Unser Ziel ist es, unsere Gäste dabei zu unterstützen, erfolgreich zu sein
  und dafür das perfekte Umfeld zu bieten, dass sie in ihrer Situation benötigen.“</FirstP>

        <br/>

        <P>Wir bieten mit unserem Hybrid Thinking Konzept bereits Lösungen, wo sich die Konkurrenz noch die Haare rauft.
          Dadurch ist es uns möglich aktuelle Bedürfnisse unserer Gäste zu verstehen und innovative Lösungen zeitnah und
          flexibel mit viel Liebe fürs Detail umzusetzen. Wir glauben an eine moderne Geschäftsfeldentwicklung,
          die leidenschaftliches Denken mit maßgeschneidertem Service kombiniert.
          Vilhelm7 Coworking – Die richtige Umgebung für Ihre Projekte.</P>


        <h2>Heute schon an Morgen denken</h2>
        <br/>
        <P>Wir entwickeln uns gemeinsam mit unseren Gästen, denn während so mancher noch über ein Problem nachdenkt,
          basteln wir schon an der Lösung. Und genau diese Philosophie ist es, die Vilhelm7 Boutique Coworking so einzigartig macht.
          Durch unseren Blick für das Besondere, bieten unsere Private Offices die perfekten Räumlichkeiten für Ihr Team, um konzentriert und inspiriert zu arbeiten.
          Dabei entscheiden Sie, wieviel Privatsphäre Sie brauchen und wieviel Community Sie wünschen.</P>

        <h2>Und wir bieten noch mehr</h2>
        <br/>
        <ul>
        <li>Authentisches Flair</li>
        <li>Hochwertiges Ambiente</li>
        <li>Loungebereich</li>
        <li>Rezeption</li>
        <li>Schlüsselservice</li>
        <li>Verlässlicher Postdienst</li>
        <li>Tägliches Housekeeping</li>
        </ul>
<br/>
<br/>
        <h2>Hört sich gut an? Lassen Sie uns besprechen, wie wir Ihnen helfen können</h2>
        <br/>
        <br/>

        <br/>
        <Flex>
          <OfferForm/>
          <Img src={img}></Img>
        </Flex>
        <br/>
        <br/>
        <br/>
        <h2>Das sagen unsere Gäste</h2>
        <br/>
        <TwitterGuestReviews/>
        <br/>
        <h2>Haben Sie noch Fragen? Wir helfen Ihnen gerne persönlich weiter!</h2>
        <Link to={'/kontakt'}>Zum Kontaktformular</Link>




      </Container>
    </Page>
  </IndexLayout>
    )
  }
}

export default Philosophie
