import * as React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { SocialIcon } from 'react-social-icons';
import moment from "moment";
import { TwitterTweetEmbed,  } from 'react-twitter-embed';

import {Location, navigate} from "@reach/router";






const StyledReviews = styled.div`

`


const Tweet = styled(TwitterTweetEmbed)`

`



class TwitterGuestReviews extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isBefore:false
    }
  }


  componentDidMount(){

  }

  render() {
    return(
    <StyledReviews >
      <blockquote className="twitter-tweet" data-lang="en" data-dnt="true" data-theme="light"><p lang="de"
                                                                                                 dir="ltr">Berliner
        Hotels ächzen unter Corona: Gäste sind rar. Mit Kreativität kontert das Kreuzberger Boutique-Hotel vilhelm7 die
        Krise. Die Vilhelminer wandeln Zimmer in Coworking- und Heimarbeitsplätze um <a
          href="https://twitter.com/hashtag/berlinshutdown?src=hash&amp;ref_src=twsrc%5Etfw">#berlinshutdown</a> <a
          href="https://twitter.com/hashtag/coronavirus?src=hash&amp;ref_src=twsrc%5Etfw">#coronavirus</a> <a
          href="https://twitter.com/hashtag/berlin?src=hash&amp;ref_src=twsrc%5Etfw">#berlin</a> <a
          href="https://t.co/d9YK3lJoRy">https://t.co/d9YK3lJoRy</a> <a
          href="https://t.co/YsVy1iklLE">pic.twitter.com/YsVy1iklLE</a></p>&mdash; Rainer Knirsch (@RainerKnirsch) <a
        href="https://twitter.com/RainerKnirsch/status/1240201919592882181?ref_src=twsrc%5Etfw">March 18, 2020</a>
      </blockquote>
      <SocialIcon style={{height: 55, width: 55}} url="https://twitter.com/v7coworking"
                  fgColor="rgb(29, 161, 242)" bgColor="rgba(255,255,255,0.0)"/>
    </StyledReviews>

    )
  }
}


export default TwitterGuestReviews
